<template>
    <div class="widget_layout">
        <UserDefinedBanner></UserDefinedBanner>

        <div class="layout_page_search">
            <div class="search_box">
                <PageSearch :title="pageSearchTitle" :placeholder="pageSearchPlaceholder" @update:value="onCheckPlugin">
                </PageSearch>
            </div>
        </div>

        <div class="widget_slot">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
/** 
 * @description widget layout 层 props 后续补充
 */

import { mapActions, mapGetters } from 'vuex';
import { dealRouterPath } from './data';
import { pageSearchTitle, pageSearchPlaceholder, NODATA_PAGE_NAME, DATA_PAGE_NAME } from "./data"
import UserDefinedBanner from '@/components/userDefinedBanner/index.vue'
import PageSearch from "@/components/pageSearch/index.vue"

export default {
    name: "widgetLayout",
    components: {
        UserDefinedBanner,
        PageSearch,
    },
    computed: {
        ...mapGetters(['getJournalData', 'getJourSearchParams']),
        commonCondition: {
            get() {
                return this.getJournalData.commonCondition;
            },
            set(value) {
                this.setCommonCondition(value);
            }
        }
    },
    watch: {
        commonCondition(newValue) {
            this.params.commonCondition = newValue;
        }
    },
    data() {
        return {
            DATA_PAGE_NAME,
            NODATA_PAGE_NAME,
            pageSearchTitle,
            pageSearchPlaceholder,
        }
    },
    methods: {
        ...mapActions(['fetchJournalData']),
        async onCheckPlugin(inputValue) {
            // 重置条件
            const params = { page: 1, pageSize: this.getJourSearchParams.pageSize, commonCondition: inputValue, journalName: '', publisherName: "" }
            delete this.getJourSearchParams.desc;
            delete this.getJourSearchParams.asc;

            this.$store.commit('setJourSearchParams', params);

            const router = this.$router
            const route = this.$route

            await this.fetchJournalData(this.params);

            dealRouterPath(route.path, this.getJournalData.total, router, params)
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.widget_layout {
    .header-bg {
        background-image: url(../../assets/images/widget_plusbj.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .layout_page_search {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .layout_page_search {
        .search_box {
            @include responsive-width(1200px);

            .is_plugin {
                width: 601px;
            }
        }
    }
}

@media (max-width: 768px) {
    .widget_layout {
        .layout_page_search {
            .search_box {
                .is_plugin {
                    width: 100%;
                }
            }
        }
    }
}
</style>