<template>
    <div class="data_table">
        <el-table :data="tableData" style="width: 100%" stripe :cell-class-name="getTableCellClassName"
            @expand-change="onTableColumnExpand" @row-click="onTableRowClick" @sort-change="onTableSortChange"
            ref="tableRef">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <div class="expand_content">
                        <div class="content_item" v-for="(item, index) in props.row.normal" :key="index"
                            @click="goToMokeLink(item, props.row.publisherId, props.row.id)">
                            {{ item }} </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Journal Name" prop="journalName" :width="btnAlign == 'center' ? 'auto' : '400'">
            </el-table-column>
            <el-table-column label="ISSN/E-ISSN" prop="issn">
            </el-table-column>
            <el-table-column label="Publisher" prop="publisherName" :width="btnAlign == 'center' ? '84' : 'auto'">
            </el-table-column>
            <el-table-column label="Country" prop="country" align="center" :width="btnAlign == 'center' ? '84' : 'auto'">
            </el-table-column>
            <el-table-column label="Status" prop="status">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 2 ? 'info' : ''">{{ getStatusText(scope.row.status) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="Preview" :align="btnAlign">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" plain @click="onTableExpand(scope.row)"
                        v-if="scope.row.status == 1">Preview</el-button>
                </template>
            </el-table-column>
            <el-table-column label="Contact" :width="btnAlign == 'center' ? '90': 'auto'">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" plain @click="onDownload(scope.row.journalWebsite)"
                        v-if="scope.row.status != 2">Contact
                        us</el-button>
                </template>
            </el-table-column>
        </el-table>

        <ContactusDialogVue ref="contactusDialogRef"></ContactusDialogVue>
    </div>
</template>

<script>
import { getPaperList } from '@/api/widget';
import ContactusDialogVue from './contactusDialog.vue';

export default {
    props: {
        tableData: {
            type: Array,
            default: () => []
        }
    },
    components: {
        ContactusDialogVue
    },
    data() {
        return {
            btnAlign: ""
        }
    },
    methods: {
        async onTableColumnExpand(row) {
            const params = { id: row.id, publisherId: row.publisherId }

            await getPaperList(params).then(res => {
                row.normal = [...res.data.normal]
            }).catch(() => {
                row.normal.length = 0
            })
        },
        onTableExpand(row) {
            this.$refs.tableRef.toggleRowExpansion(row, true)
        },
        onTableRowClick(row) {
            if (row.status == 0) return

            if (row.status == 1) this.onTableExpand(row)
            if (row.status == 2) this.goToBlank(row.journalWebsite)
        },
        onTableSortChange(column) {
            this.$emit('update:sort', column.order)
        },
        onDownload(row) {
            this.$refs.contactusDialogRef.openDialog(row)
        },
        getTableCellClassName(cell) {
            if (cell.row.status == 0 || cell.row.status == 2) {
                return 'no_data_expand'
            }
        },
        getStatusText(status) {
            if (status === 0) return 'Not produced'
            if (status === 1) return 'Simulated'
            if (status === 2) return 'Joint'
        },
        goToBlank(url) {
            window.open(url, '_blank')
        },
        goToMokeLink(url, publisherId, id) {
            window.open("http://47.97.203.109:9999/links/" + publisherId + '/' + id + '/' + url, '_blank')
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.btnAlign = width < 768 ? 'center' : 'left';
        },
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang="scss">
.data_table {
    .expand_content {
        .content_item {
            padding: 20px;
            padding-left: 60px;
            transition: all .3s;

            &:hover {
                cursor: pointer;
                background-color: #f5f7fa;
            }

            &:nth-child(odd) {
                background-color: #eff2f4;
            }
        }
    }

    .no_data_expand {
        .el-table__expand-icon {
            font-size: 0;
        }
    }

    .el-table__row {
        &:hover {
            cursor: pointer;
        }
    }

    .el-table thead {
        color: unset;
    }

    .table_codes {
        .generate_codes {
            // margin-left: 18px;

            &:hover {
                cursor: pointer;
            }

            img {
                margin-right: 10px;
            }

            .generate_text {
                font-size: 16px;
                color: black;
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 768px) {
    .data_table {
        .el-table .cell {
            padding-left: 4px;
            padding-right: 4px;

            .el-button {
                padding: 9px 8px;
            }

            .el-tag {
                padding: 0 8px;
            }
        }
    }
}
</style>