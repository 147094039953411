<template>
    <div class="pub_carousel_box" v-if="pubList.length > 0">
        <div class="pub_carousel_title">Our Clients</div>
        <div class="pub_carousel">
            <div class="pub_carousel_content" @mouseenter="disableScroll" @mouseleave="enableScroll">
                <div class="content_pub_list" ref="pubListRef"
                    :style="{ height: height + 'px', paddingRight: scrollBarWidth + 'px' }">
                    <div v-for="(item, index) in pubList" :key="index"
                        :class="index === activeIndex ? 'content_pub_item content_pub_item_active' : 'content_pub_item'"
                        :style="{ transform: `translateY(${turning * - itemHeight}px)` }"
                        @click="onPubItemClick(index)">
                        {{ item.journalName }}
                    </div>

                    <ScrollBar :total="pubList.length" :current="activeIndex" :pageSize="activePageSize"
                        :height="height" :width="scrollBarWidth" :visibility="scrollBarVisibility"></ScrollBar>
                </div>

                <div class="content_widget_img"
                    :style="{ height: height + 'px', background: `url(${pubList[activeIndex]?.imgurl})` }"
                    ref="pubImgRef" @click="goToPage(pubList[activeIndex]?.website)">
                </div>
                <!-- <div class="content_widget_img" :style="{ height: height + 'px'}" ref="pubImgRef">
                    <img :src="pubList[activeIndex]?.imgurl" alt="" @click="goToPage(pubList[activeIndex]?.website)">
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { throttle } from 'lodash';
import { getJournalCarousel } from '@/api/widget';
import { testList } from "./data"
import ScrollBar from "../scrollBar/index.vue"

export default {
    components: {
        ScrollBar
    },
    data() {
        return {
            testList,
            pubList: [],
            height: 600,
            activeIndex: 0,
            activePageSize: 10,
            turning: 0,
            itemHeight: 0,
            autoChangeItemTime: 5000,
            scrollBarWidth: 5,
            isLoading: false,
            isMobileDevices: false,
            autoClickInterval: null,
            scrollBarVisibility: "visible",
            imgBaseUrl: "http://116.255.227.209:9000"
        }
    },
    methods: {
        // 期刊项点击事件
        onPubItemClick(index) {
            if (index < 0) return
            this.activeIndex = index

            const { distanceFromTop, distanceFromBottom } = this.calculateDistance(index)

            this.handleScroll(index, distanceFromTop, distanceFromBottom)
        },
        // 从现有的期刊列表中获取 page 到 pageSize 的期刊数据
        async getPubList(page, pageSize) {
            // 0  0 10
            // 1  5 14
            // 2  15 24
            // 3  25 34
            // const start = page >= 1 ? pageSize / 2 * page : 0;
            // const end = page >= 1 ? start + this.activePageSize : 10;

            await getJournalCarousel().then((res) => {
                this.pubList = res.data.map(item => {
                    let newImgUrl = item.imgurl;

                    if (item.imgurl.includes(this.imgBaseUrl)) {
                        newImgUrl = item.imgurl.split(this.imgBaseUrl)[1];
                    }

                    return {
                        ...item,
                        imgurl: newImgUrl
                    };
                })

            }).catch((err) => {
                this.pubList = []
            })
        },
        // 判断元素是否到达底部
        calculateDistance(index) {
            const pubListElement = this.$refs.pubListRef;
            const selectedItem = pubListElement.children[index];

            const containerHeight = pubListElement.clientHeight;
            const containerTop = pubListElement.getBoundingClientRect().top;

            const itemRect = selectedItem.getBoundingClientRect();
            const itemTop = itemRect.top;
            const itemBottom = itemRect.bottom;
            const itemHeight = itemRect.height;

            const distanceFromTop = itemTop - containerTop;
            const distanceFromBottom = containerTop + containerHeight - itemBottom;

            if (this.itemHeight === 0) this.itemHeight = itemHeight

            // console.log(`选中元素距离容器顶部: ${distanceFromTop}px`);
            // console.log(`选中元素距离容器底部: ${distanceFromBottom}px`);

            return {
                distanceFromTop,
                distanceFromBottom
            };
        },
        // 处理滑动基数
        handleScroll(index, top, bottom) {
            if (index < this.activePageSize - 1) return this.turning = 0
            if (this.pubList.length - index < this.activePageSize) return this.turning = this.pubList.length - this.activePageSize

            if ((top <= 0 || top < this.itemHeight) && index > 0) {
                this.turning -= this.activePageSize / 2
            }

            if (bottom <= 0 || bottom < this.itemHeight) {
                this.turning += this.activePageSize / 2
            }
        },
        // 处理元素内滚动
        handleWheel(event) {
            event.preventDefault();

            this.throttledHandleWheel(event);
        },
        // 节流后的实际处理函数 throttle(function, 500) 500为节流时间间隔
        throttledHandleWheel: throttle(function (event) {
            if (this.isLoading) return;

            if (event.deltaY > 0) {
                this.loadMoreItems('down');
            } else if (event.deltaY < 0) {
                this.loadMoreItems('up');
            }
        }, 500),
        // 滚动切换数据
        loadMoreItems(type) {
            if (this.activeIndex === this.pubList.length - 1) return this.resetAutoClick()

            this.isLoading = true;

            this.onPubItemClick(type === 'down' ? this.activeIndex + 1 : this.activeIndex - 1)
            this.isLoading = false;
        },
        // 禁止滚动
        disableScroll() {
            window.addEventListener('wheel', this.preventScroll, { passive: false });
            if (!this.isMobileDevices) window.addEventListener('touchmove', this.preventScroll, { passive: false });

            this.disableAutoClick()

            this.scrollBarVisibility = 'visible'
        },
        // 允许滚动
        enableScroll() {
            window.removeEventListener('wheel', this.preventScroll);
            if (!this.isMobileDevices) window.removeEventListener('touchmove', this.preventScroll);

            this.enableAutoClick()

            this.scrollBarVisibility = 'hidden'
        },
        // 阻止默认滚动事件
        preventScroll(event) {
            event.preventDefault();
        },
        // 开始自动轮播
        enableAutoClick() {
            this.autoClickInterval = setInterval(() => {
                this.loadMoreItems('down');
            }, this.autoChangeItemTime);
        },
        // 禁用自动轮播
        disableAutoClick() {
            if (this.autoClickInterval) {
                clearInterval(this.autoClickInterval);
                this.autoClickInterval = null;
            }
        },
        // 重置自动轮播序数
        resetAutoClick() {
            this.activeIndex = 0;
            this.turning = 0;
            this.onPubItemClick(this.activeIndex);
        },
        // 根据页面尺寸 更改是否滚动页面参数
        updatePageTouch() {
            const width = window.innerWidth;
            this.isMobileDevices = width < 1024;
        },
        goToPage(page) {
            window.open(page, '_blank')
        }
    },
    async mounted() {
        window.addEventListener('resize', this.updatePageTouch);

        await this.getPubList()
        this.$refs.pubListRef.addEventListener('wheel', this.handleWheel);
        this.$refs.pubImgRef.addEventListener('wheel', this.handleWheel);
        this.enableAutoClick();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePageTouch);

        this.$refs.pubListRef.removeEventListener('wheel', this.handleWheel);
        this.$refs.pubImgRef.removeEventListener('wheel', this.handleWheel);

        if (this.autoClickInterval) {
            clearInterval(this.autoClickInterval);
        }
    },
}
</script>

<style lang='scss'>
@import "./style.scss";
</style>